<template>
  <div class="videoItem">
    <ScaleImage>
      <div class="imgBox">
        <PlaceholderImage
          :src="data.imgUrl"
          :width="620"
          :height="348"
          :alt="data.title"
          @load="onLoad"
        />
        <MaskLayer
          v-if="!isMobile"
          class="maskF"
        >
          <span class="videoBtn">播放
            <img
              alt=""
              :src="require('@/assets/ShuJiuXiang/video.png')"
            /></span>
        </MaskLayer>
        <MaskLayer class="maskS">
          <img
            class="videoBtn"
            alt=""
            :src="require('@/assets/ShuJiuXiang/video.png')"
          />
        </MaskLayer>
      </div>
      <p class="title">{{ data.title }}</p>
    </ScaleImage>
  </div>
</template>

<script>
import { Placeholder, Scale } from '@/components/Image';
import MaskLayer from '@/components/Mask';
import { mapState } from 'vuex';
export default {
  components: {
    PlaceholderImage: Placeholder,
    MaskLayer,
    ScaleImage: Scale,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['isMobile']),
  },
  methods: {
    onLoad(...args) {
      console.log('args: ', args);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.maskF {
  background: $mask-color-1;
  @include hide;
  .videoBtn {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    > img {
      width: 21px;
      height: 24px;
      margin-left: 20px;
    }
  }
}
.maskS {
  background: none;
  .videoBtn {
    width: 31px;
    height: 35px;
  }
}
.videoItem {
  ::v-deep {
    .scaleImg {
      border-bottom: 1px solid $sub-color-2;
      padding: 40px 0;
      cursor: pointer;
    }
  }
  &:nth-child(1),
  &:nth-child(2) {
    ::v-deep {
      .scaleImg {
        border-top: 1px solid $sub-color-2;
      }
    }
  }
  .imgBox {
    position: relative;
  }
  .title {
    font-size: 16px;
    line-height: math.div(21, 16);
    @include text-nowrap;
    margin-top: 20px;
  }
  @media screen and (min-width: $hover-point) {
    .maskS,
    .maskF {
      transition: 400ms;
    }
    &:hover {
      .maskS {
        @include hide;
      }
      .maskF {
        @include show;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    .title {
      margin-top: 10px;
      color: $text-color-2;
      font-size: 14px;
    }
    + .videoItem {
      margin-top: 15px;
    }
    ::v-deep {
      .scaleImg {
        background: #fff;
        padding: 15px;
        border-radius: 6px;
        border: none;
        box-shadow: $card-shadow-1;
      }
    }
    &:nth-child(1),
    &:nth-child(2) {
      ::v-deep {
        .scaleImg {
          border: none;
        }
      }
    }
  }
}
</style>