import request from '@/utils/request';

const PATH = '/v1/news';

// 1.	基本信息
export function getBaseInfo(params = {}) {
  return request({
    url: PATH + '/info',
    method: 'GET',
    params,
  });
}

// 5.	近期活动
export function getActivity(params = {}) {
  return request({
    url: PATH + '/activity',
    method: 'GET',
    params,
  });
}

// 4.	视频中心
export function getVideo(params = {}) {
  return request({
    url: PATH + '/video',
    method: 'GET',
    params,
  });
}

// 2.	新闻公告
export function getNews(params = {}) {
  return request({
    url: PATH + '/news',
    method: 'GET',
    params,
  });
}

// 6.	新闻资讯详情
export function getDetail(params = {}) {
  return request({
    url: PATH + '/news_detail',
    method: 'POST',
    params,
  });
}

export function getActivityDetail(params = {}) {
  return request({
    url: PATH + '/activity_detail',
    method: 'POST',
    params,
  });
}

// 下个新闻详情
export function getNextNews(params = {}) {
  return request({
    url: PATH + '/next_news',
    method: 'POST',
    params,
  });
}

// 8.上个新闻详情
export function getLastNews(params = {}) {
  return request({
    url: PATH + '/last_news',
    method: 'POST',
    params,
  });
}

// 11.下个活动详情
export function getNextActivity(params = {}) {
  return request({
    url: PATH + '/next_activity',
    method: 'POST',
    params,
  });
}

// 8.上个新闻详情
export function getLastActivity(params = {}) {
  return request({
    url: PATH + '/last_activity',
    method: 'POST',
    params,
  });
}
