<template>
  <div class="newsCard">
    <div class="pic">
      <img
        :src="data.imgUrl"
        alt=""
      />
    </div>
    <p class="date"><span class="year">{{ data.year }}<i class="dot">.</i></span><span class="month">{{ data.month }}</span></p>
    <div class="txts">
      <p class="title">{{ data.title }}</p>
      <p class="desc">{{ data.desc }}</p>
    </div>
    <div class="more">了解详情
      <img
        :src="require('@/assets/ShuJiuXiang/b-icon-detail@2x.png')"
        alt=""
        class="icon icon1"
      />
      <img
        :src="require('@/assets/ShuJiuXiang/g-icon-detail@2x.png')"
        alt=""
        class="icon icon2"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.newsCard {
  display: flex;
  align-items: center;
  position: relative;
  padding: 50px 0;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: $sub-color-2;
    z-index: 2;
    position: absolute;
    opacity: 0.2;
  }
  &::before {
    top: 0;
    margin-top: -1px;
  }
  &::after {
    bottom: 0;
  }
  .date {
    color: $text-color-2;
    width: (190 + 80) / 1280 * 100%;
    > span {
      display: block;
    }
    .year {
      font-size: 16px;
      line-height: 18px;
      .dot {
        display: none;
      }
    }
    .month {
      font-size: 32px;
      line-height: 36px;
      margin-top: 15px;
    }
  }
  .txts {
    flex: 1;
    overflow: hidden;
    .title {
      font-size: 24px;
      line-height: 1.33;
    }
    .title,
    .desc {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      color: $text-color-3;
      font-size: 18px;
      line-height: 1.33;
      margin-top: 18px;
      @include text-nowrap;
    }
  }
  .more {
    display: flex;
    align-items: center;
    margin-left: 60px;
    .icon {
      width: 26.5px;
      height: 21px;
      margin-left: 8px;
    }
    .icon2 {
      display: none;
    }
  }
  .pic {
    width: (540 / 1280) * 100%;
    overflow: hidden;
    position: absolute;
    right: -115px;
    bottom: -45px;
    opacity: 0;
    visibility: hidden;
    > img {
      display: block;
      width: 100%;
    }
  }
  @media screen and (min-width: $hover-point) {
    transition: 300ms;
    &::before,
    &::after,
    .txts .desc,
    .date {
      transition: 300ms;
    }
    .pic {
      transition: 400ms;
    }
    &:hover {
      color: $sub-color-2;
      &::before,
      &::after {
        opacity: 1;
      }
      .pic {
        opacity: 0.2;
        visibility: visible;
      }
      .txts .desc,
      .date {
        color: $sub-color-2;
      }
      .more {
        .icon1 {
          display: none;
        }
        .icon2 {
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-2) {
    .pic {
      bottom: -25px;
    }
  }
  @media screen and (max-width: $res-point-6) {
    .pic {
      display: none;
    }
  }
  @media screen and (max-width: $res-point-10) {
    display: block;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    box-shadow: $card-shadow-1;
    .pic {
      display: block;
      opacity: 1;
      visibility: visible;
      position: static;
      width: 100%;
      margin-bottom: 10px;
    }
    &::after,
    &::before {
      display: none;
    }
    .date {
      color: $text-color-3;
      display: flex;
      width: 100%;
      margin-bottom: 5px;
      .month {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
      }
      .year {
        font-size: 12px;
        .dot {
          display: inline;
        }
      }
    }
    .txts {
      padding-bottom: 5px;
      .title {
        font-size: 18px;
        color: $text-color-4;
        font-weight: bold;
      }
      .desc {
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .more {
      display: none;
    }
  }
}
</style>