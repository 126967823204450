<template>
  <div class="page-news">
    <PageBannerBox>
      <FSImg :imgUrl="this.bannerInfo.imgUrl" />
      <BannerText
        :en="this.bannerInfo.en"
        :ch="this.bannerInfo.ch"
        :navList="navList"
        @changeActiveNav="changeActiveNav"
        :active="currentNav"
      />
      <SJXPoint v-if="!isMobile" />
    </PageBannerBox>
    <PageContent>
      <div v-loading="loading">
        <div
          v-if="newsList.length && currentNav !== 'sjxNewsVideo'"
          class="newsList"
        >
          <NewsItem
            v-for="item in newsList"
            :key="item.id"
            :data="item"
            @click.native="goDetail(item)"
          />
        </div>
        <div
          class="videoList"
          v-else-if="videoData.length && currentNav === 'sjxNewsVideo'"
        >
          <VideoItem
            v-for="item in videoData"
            :key="item.id"
            :data="item"
            @click.native="onPlay(item.videoUrl, item.imgUrl)"
          />
        </div>
        <el-empty v-else-if="!newsList.length || !videoData.length"></el-empty>
      </div>
    </PageContent>
    <SideNav
      :navList="navList"
      :active="currentNav"
      @changeActiveNav="changeActiveNav"
      v-if="!isNearMobile"
    />
    <VideoModal
      :visible="!!currentVideo"
      :onClose="() => { currentVideo = ''; }"
      :src="currentVideo"
      :poster="videoPoster"
    />
  </div>
</template>

<script>
import { FSImg } from '@/components/Banner';
import BannerText from '../components/BannerText';
import PageBannerBox from '../components/Layout/PageBannerBox.vue';
import { SJXPoint } from '@/components/DownPoint';
import PageContent from '../components/Layout/PageContent.vue';
import NewsItem from '../components/Card/News.vue';
import SideNav from '../components/SideNav';
import { mapState } from 'vuex';
import VideoItem from '../components/Card/Video.vue';
import VideoModal from '@/components/Video/VideoModal.vue';
import _ from 'lodash';
import { getBaseInfo, getActivity, getNews, getVideo } from '@/api/ShuJiuXiang/news';
import { navList } from './data';

export default {
  components: {
    FSImg,
    BannerText,
    PageBannerBox,
    SJXPoint,
    PageContent,
    NewsItem,
    SideNav,
    VideoItem,
    VideoModal,
  },
  data() {
    return {
      navList,
      currentNav: '',
      currentVideo: '',
      videoPoster: '',
      baseInfo: {},
      loading: false,
      listData: [],
    };
  },
  mounted() {
    this.getBaseInfo();
    this.initCurrentNav(this.$route);
  },
  methods: {
    // 初始化当前导航
    initCurrentNav(target) {
      const { type } = target.params;
      this.currentNav = type ? `sjxNews${_.capitalize(type)}` : '';
      this.getData();
    },
    // 切换导航
    changeActiveNav(key, url) {
      this.$router.push(url);
      this.currentNav = key;
    },
    // 播放视频
    onPlay(videoUrl, imgUrl) {
      this.currentVideo = videoUrl;
      this.videoPoster = imgUrl;
    },
    // 查看详情
    goDetail(itemData) {
      const { flag, link, id } = itemData;
      const { type } = this.$route.params;
      // 外链接
      if (flag === '1') {
        window.open(link, '_blank');
      } else {
        this.$router.push({ name: 'sjxNewsDetail', params: { id, type } });
      }
    },
    // 基本信息
    async getBaseInfo() {
      const res = await getBaseInfo();
      this.baseInfo = res;
    },
    // 获取列表数据
    async getData() {
      this.loading = true;
      try {
        let res = [];
        if (this.currentNav === 'sjxNewsActivity') {
          res = await getActivity();
        } else if (this.currentNav === 'sjxNewsNotice') {
          res = await getNews();
        } else {
          res = await getVideo();
        }
        this.listData = [...res];
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState(['isMobile', 'isNearMobile']),
    bannerInfo() {
      const { type } = this.$route.params;
      let en = '';
      let ch = '';
      let imgUrl = '';
      switch (type) {
        case 'activity':
          en = this.baseInfo.activityChinese;
          ch = this.baseInfo.activityEnglish;
          imgUrl = this.baseInfo.activityImage;
          break;
        case 'notice':
          en = this.baseInfo.newChinese;
          ch = this.baseInfo.newEnglish;
          imgUrl = this.baseInfo.newImage;
          break;
        default:
          en = this.baseInfo.videoChinese;
          ch = this.baseInfo.videoEnglish;
          imgUrl = this.baseInfo.videoImage;
          break;
      }
      return { en, ch, imgUrl };
    },
    newsList() {
      const { type } = this.$route.params;
      if (type === 'video') return [];
      return this.listData.map((d) => {
        const date = d.createTime;
        return {
          id: d[type === 'activity' ? 'activityId' : 'newsId'],
          title: d[type === 'activity' ? 'activityTitle' : 'newsTitle'],
          desc: d[type === 'activity' ? 'activityBrief' : 'newsBrief'],
          imgUrl: d[type === 'activity' ? 'activityImage' : 'newsImage'],
          flag: d[type === 'activity' ? 'activityLinkFlag' : 'newsLinkFlag'],
          link: d[type === 'activity' ? 'activityLink' : 'newsLink'],
          year: date ? date.slice(0, 4) : '',
          month: date ? date.slice(5, 10).replace('-', '.') : '',
        };
      });
    },
    videoData() {
      const { type } = this.$route.params;
      if (type !== 'video') return [];
      return this.listData.map((d) => ({
        id: d.videoId,
        title: d.videoTitle,
        imgUrl: d.videoImage,
        videoUrl: d.videoUrl,
      }));
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.initCurrentNav(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.page-news {
  ::v-deep .pageContent {
    padding-top: (250 * 0.8 / 1920) * 100%;
    padding-bottom: (300 * 0.8 / 1920) * 100%;
  }
  @media screen and (max-width: $res-point-6) {
    padding-top: 60px;
  }
}
.videoList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  ::v-deep {
    .videoItem {
      width: 50%;
      padding-left: 40px;
    }
  }
  @media screen and (max-width: $res-point-8) {
    margin-left: 0;
    ::v-deep {
      .videoItem {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}
@media screen and (max-width: $res-point-10) {
  .newsList {
    ::v-deep .newsCard {
      + .newsCard {
        margin-top: 15px;
      }
    }
  }
}
</style>
